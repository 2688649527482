/*
    C-DIS
    v0.1.0
    Composant générique CSS ITADS
    https://github.com/ita-design-system/c-dis.scss
*/
// SCSS map
$briks-components-generic: ( 
    // Nom du composant
    dis: ( 
        // Composant activé true ou false
        enabled: true, 
        // Responsive activé true ou false
        responsive: true, 
        // Liste des propriétés c-dis par défaut
        defaults: (
            --ita-gap: 0px,
            gap: var(--ita-gap)
        ),
        // Rendu: 
        // c-dis {
        //      --ita-gap: 0px;
        //      gap: var(--ita-gap);
        // }
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // c-dis m-none
            none: (
                display: none
            ),
            // c-dis m-inline
            inline: (
                display: inline
            ),
            // c-dis m-block
            block: (
                display: block
            ),
            // c-dis m-inline-block
            inline-block: (
                display: inline-block
            ),
            // c-dis m-flex
            flex: (
                display: flex
            ),
            // c-dis m-inline-flex
            inline-flex: (
                display: inline-flex
            ),
            // c-dis m-grid
            grid: (
                display: grid
            ),
            // c-dis m-inline-grid
            inline-grid: (
                display: inline-grid
            ),
            // c-dis m-webkit-box (utile pour les ellipses à plusieurs lignes)
            webkit-box: (
                display: -webkit-box
            ),
            // FLEX
            // Axe main
            // c-dis m-flex m-main-start
            main-start: ( 
                justify-content: flex-start
            ),
            // c-dis m-flex m-main-center
            main-center: ( 
                justify-content: center
            ),
            // c-dis m-flex m-main-end
            main-end: (
                justify-content: flex-end
            ),
            // c-dis m-flex m-main-space-between
            main-space-between: ( 
                justify-content: space-between
            ),
            // c-dis m-flex m-main-space-around
            main-space-around: ( 
                justify-content: space-around
            ),
            // c-dis m-flex m-main-space-evenly
            main-space-evenly: ( 
                justify-content: space-evenly
            ),
            // Axe cross
            // c-dis m-flex m-cross-center
            cross-center: ( 
                align-items: center
            ),
            // c-dis m-flex m-cross-baseline
            cross-baseline: ( 
                align-items: baseline
            ),
            // c-dis m-flex m-cross-start
            cross-start: ( 
                align-items: flex-start
            ),
            // c-dis m-flex m-cross-end
            cross-end: ( 
                align-items: flex-end
            ),
            // Wrap / retour à la ligne
            // c-dis m-flex m-nowrap
            nowrap: ( 
                flex-wrap: nowrap
            ),
            // c-dis m-flex m-wrap
            wrap: ( 
                flex-wrap: wrap
            ),
            // c-dis m-flex m-wrap-reverse
            wrap-reverse: ( 
                flex-wrap: wrap-reverse
            ),
            // Direction
            // c-dis m-flex m-column
            column: ( 
                flex-direction: column
            ),
            // c-dis m-flex m-column-reverse
            column-reverse: ( 
                flex-direction: column-reverse
            ),
            // c-dis m-flex m-cross-row-reverse
            row-reverse: ( 
                flex-direction: row-reverse
            ),
            // ELLIPSE 2 lignes
            // A utiliser avec c-txt et c-dim
            // c-dis m-webkit-box
            // c-dim m-o-hidden
            // c-txt m-ellipsis-2
            ellipsis-2: (
                '-webkit-box-orient': vertical,
                '-webkit-line-clamp': 2
            ),
            // ELLIPSE 3 lignes
            // A utiliser avec c-txt et c-dim
            // c-dis m-webkit-box
            // c-dim m-o-hidden
            // c-txt m-ellipsis-3
            ellipsis-3: (
                '-webkit-box-orient': vertical,
                '-webkit-line-clamp': 3
            ),
        )
    )
);